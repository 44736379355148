import { snakeCase } from 'lodash'
import * as Yup from 'yup'
import * as ja from 'yup-locale-ja'
import { isPositionRequired, sizeCheckRequiredHeadingTypes, nullish } from './ArticleParse'
Yup.setLocale(ja.suggestive)

export const figureSchema = Yup.object().shape({
  figureType: Yup.string().required(),
  filename: Yup.string(),
  numberOfOccupyingColumns: Yup.number().required().min(1),
  numberOfOccupyingRows: Yup.number().required().min(1),
  fluid: Yup.boolean().required(),
  verticalPosition: Yup.number().min(0).nullable(true),
  horizontalPosition: Yup.number().min(0).nullable(true)
})

const headingSchema = Yup.object().shape({
  numberOfOccupyingColumns: Yup.number().required().min(1),
  numberOfOccupyingRows: Yup.number().required().min(1)
})

const isRectDefinedArea = (area) => {
  return [area.x, area.y, area.width, area.height].every(v => !nullish(v))
}

const parseArea = (area) => {
  const { x, y, width, height } = area
  const [numX, numY, numWidth, numHeight] = [x, y, width, height].map(s => (nullish(s) ? undefined : Number(s)))
  // console.log('fo', { numWidth, width, numHeight, height })
  return {
    top: numY,
    right: numX,
    bottom: (nullish(numY) || nullish(numHeight)) ? undefined : numY + numHeight,
    left: (nullish(numX) || nullish(numWidth)) ? undefined : numX + numWidth
  }
}

const parseFigureArea = (fig) => {
  const top = nullish(fig.verticalPosition) ? undefined : Number(fig.verticalPosition)
  const right = nullish(fig.horizontalPosition) ? undefined : Number(fig.horizontalPosition)
  const width = nullish(fig.numberOfOccupyingRows) ? undefined : Number(fig.numberOfOccupyingRows)
  const height = nullish(fig.numberOfOccupyingColumns) ? undefined : Number(fig.numberOfOccupyingColumns)
  const bottom = [top, height].some(nullish) ? undefined : top + height
  const left = [right, width].some(nullish) ? undefined : right + width
  return { top, right, bottom, left }
}

const areasOverlapRTLB = (area1, area2) => {
  const { right: right1, top: top1, left: left1, bottom: bottom1 } = area1
  const { right: right2, top: top2, left: left2, bottom: bottom2 } = area2
  if ([right1, top1, right2, top2].some(nullish)) {
    return false
  }
  const area1HasFullArea = [left1, right1, top1, bottom1].every(v => !nullish(v))
  const area2HasFullArea = [left2, right2, top2, bottom2].every(v => !nullish(v))
  if (!area1HasFullArea && !area2HasFullArea) {
    return false
  } else if (area1HasFullArea && area2HasFullArea) {
    return left1 > right2 && right1 < left2 && top1 < bottom2 && bottom1 > top2
  } else if (area1HasFullArea) {
    return left1 > right2 && bottom1 > top2 && (nullish(left2) || right1 < left2) && (nullish(bottom2) || top1 < bottom2)
  }
  return left2 > right1 && bottom2 > top1 && (nullish(left1) || right2 < left1) && (nullish(bottom1) || top2 < bottom1)
}

const areasOverlap = (area1, area2) => {
  if ([area1.x, area1.y, area2.x, area2.y].some(nullish)) {
    return false
  }
  const rtlbArea1 = parseArea(area1)
  const rtlbArea2 = parseArea(area2)
  return areasOverlapRTLB(rtlbArea1, rtlbArea2)
}

export const isValidArea = (layoutable, area) => {
  const { numberOfColumns, numberOfRows } = layoutable
  const minY = 0
  const minX = 0
  const maxY = numberOfColumns
  const maxX = numberOfRows
  const { right, top, left, bottom } = parseArea(area)
  // console.log('foo', { right, top, left, bottom })
  // depends on type
  return (nullish(left) || minX <= left) &&
    (nullish(right) || right <= maxX) &&
    (nullish(top) || minY <= top) &&
    (nullish(bottom) || bottom <= maxY)
}

export const validateHalfSizeImageCharacterSettings = (_, context) => {
  const {
    numberOfCharactersPerRow, imageCharacters, spaceCharacters, textCharacters
  } = context.parent
  return (imageCharacters + spaceCharacters + textCharacters) === numberOfCharactersPerRow
}

const validateLayoutable = ({
  layoutable,
  enumValues
}) => {
  // console.log('layoutable', layoutable)
  const newLayoutValidationErrors = []
  if (!layoutable?.articles?.length) {
    newLayoutValidationErrors.push({ message: '記事が登録されていません' })
    return newLayoutValidationErrors
  }
  const figureErrors = layoutable.articles.flatMap((article, articleIdx) =>
    article?.figures?.flatMap((fig, figIdx) =>
      figureSchema.isValidSync(fig)
        ? []
        : { message: `${articleIdx + 1}番目の記事の${figIdx + 1}番目の写真・図のファイルの割り当てと寸法を確認してください。` }
    ) || [])
  if (figureErrors.length > 0) {
    // console.log('figureErrors', figureErrors)
    newLayoutValidationErrors.push(...figureErrors)
  }
  const headingErrors = layoutable.articles.flatMap((article, articleIdx) =>
    article?.headings?.flatMap((heading, _headingIdx) => {
      // console.log('inside heading Check', { heading, headingIdx, valid: headingSchema.isValidSync(heading) })
      const headingType = snakeCase(heading.headingType)
      const headingTypeStr = enumValues.headingTypes[heading.headingType]
      return (!sizeCheckRequiredHeadingTypes.has(headingType) || headingSchema.isValidSync(heading))
        ? []
        : { message: `${articleIdx + 1}番目の記事の${headingTypeStr}の寸法を指定してください。` }
    }) || []
  )
  if (headingErrors.length > 0) {
    // console.log('headingErrors', headingErrors)
    newLayoutValidationErrors.push(...headingErrors)
  }
  const areaPositionErrors = layoutable.articles.flatMap((article, articleIdx) => {
    if (!isPositionRequired(article.articleType)) {
      return []
    }
    const { x, y } = article?.areas?.[0] || {}
    if (!x || !y) {
      return { message: `${articleIdx + 1}番目の在版記事の座標位置の指定が行われておりません。` }
    }
    return []
  })
  if (areaPositionErrors.length > 0) {
    newLayoutValidationErrors.push(...areaPositionErrors)
  }
  /*
  const sizeErrors = layoutable.articles.flatMap((article, articleIdx) => {
    if (isSizedArticleType(article.articleType)) {
      let undefinedSizes = 0
      const { width, height } = article?.areas?.[0] || {}
      if (!width) {
        undefinedSizes += 1
      }
      if (!height) {
        undefinedSizes += 1
      }
      if (!article.rows) {
        undefinedSizes += 1
      }
      if (undefinedSizes > 1) {
        return { message: `${articleIdx + 1}番目の記事の記事サイズ指定が正しくされていません。（行数・段数・横幅のうち、少なくとも2つはキメる必要があります。）` }
      }
    }
    return []
  })
  if (sizeErrors.length > 0) {
    console.log('sizeErrors', sizeErrors)
    newLayoutValidationErrors.push(...sizeErrors)
  }
  */
  const areas = layoutable.articles.flatMap((article, articleIdx) =>
    article?.areas?.flatMap((area, areaIdx) => ({ articleIdx, area, areaIdx })) || []
  )
  // console.log('areas', areas)
  const areaErrors = areas.flatMap(({ articleIdx, area }, idx) => (
    areas.slice(idx + 1).flatMap(({ articleIdx: targetArticleIdx, area: targetArea }) =>
      areasOverlap(area, targetArea)
        ? { message: `${articleIdx + 1}番目の記事の領域と${targetArticleIdx + 1}番目の記事の領域が重なっています。` }
        : [])
  ))
  if (areaErrors.length > 0) {
    // console.log('areaErrors', areaErrors)
    newLayoutValidationErrors.push(...areaErrors)
  }
  const areaOutOfBoundsErrors = areas.flatMap(({ articleIdx, area }) => (
    !isValidArea(layoutable, area)
      ? { message: `${articleIdx + 1}番目の記事の領域が紙面設定の範囲外です。` }
      : []
  ))
  if (areaOutOfBoundsErrors.length > 0) {
    // console.log('areaErrors', areaErrors)
    newLayoutValidationErrors.push(...areaOutOfBoundsErrors)
  }
  const rectDefinedAreas = areas.flatMap(a => (isRectDefinedArea(a.area) ? { ...a, ...{ areaRTLB: parseArea(a.area) } } : []))
  // rectDefinedAreas.flatMap()
  // console.log('areas', { areas, rectDefinedAreas })
  if (rectDefinedAreas.length > 0) {
    const figureAreaOverlapErrors = layoutable.articles.flatMap((article, articleIdx) =>
      article?.figures?.flatMap((fig, figIdx) => {
        const figArea = parseFigureArea(fig)
        return rectDefinedAreas.flatMap(a =>
          areasOverlapRTLB(a.areaRTLB, figArea)
            ? { message: `${a.articleIdx + 1}番目の記事の領域と${articleIdx + 1}番目の記事の${figIdx + 1}番目の写真・図の領域が重なっています。` }
            : [])
      }) || [])
    if (figureAreaOverlapErrors.length > 0) {
      newLayoutValidationErrors.push(...figureAreaOverlapErrors)
    }
  }
  const preambleSizeErrors = layoutable.articles.flatMap((article, articleIdx) => {
    if (nullish(article.preambleContent)) {
      return []
    }
    if (((article.preambleNumberOfRows ?? 0) === 0) ||
        ((article.preambleNumberOfCharactersPerRow ?? 0) === 0)) {
      return { message: `${articleIdx + 1}番目の記事の前文の行数が指定されていません。` }
    }
    return []
  })
  if (preambleSizeErrors.length > 0) {
    newLayoutValidationErrors.push(...preambleSizeErrors)
  }
  return newLayoutValidationErrors
}

export default validateLayoutable
